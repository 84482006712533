import React from 'react'
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from '../components/layout';
import _ from 'lodash'
import Seo from '../components/seo';

const yearPosts = (posts) => {
  return (
    <div className="pl-4">
      {posts.map(post => (
        <div key={post.slug} className="mb-2">
          <Link to={`/posts/${post.slug}`}>{post.title}</Link>
          <span className="text-xs ml-2">{new Date(post.posted_at).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</span>
        </div>
      ))}
    </div>
  )
}

export default function Archive() {
  const data = useStaticQuery(query);
  const groups = _.groupBy(data.allStrapiPost.nodes, (post) => {
    const postedDate = new Date(post.posted_at)
    return postedDate.getFullYear()
  })
  const years = _.keys(groups).reverse()
  return (
    <Layout>
      <Seo title="Archive" />
      <h1 className="mb-6">Archive</h1>
      {years.map(year =>
        <div key={year} className="mb-2">
          <h3>{year}</h3>
          {yearPosts(groups[year])}
        </div>
      )}
    </Layout>
  )
}


const query = graphql`
  query ArchiveQuery {
    allStrapiPost(sort: {fields: posted_at, order: DESC})  {
      nodes {
        slug
        title
        posted_at
      }
    }
  }`